<template>
  <div class="InterActionList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="false"
    >
      <template #searchSlot>
        <v-datepicker label="发布时间段" :maxDate="maxDate" :startTime.sync="searchParams.startDate" :endTime.sync="searchParams.endDate"></v-datepicker>
        <v-input label="标题/内容" v-model="searchParams.topicTitle"></v-input>
        <v-select2 label="所属应用" v-model="searchParams.topicType" :subjoin="taxNamesubjoin" v-bind="topicTypeParams"></v-select2>
        <v-select label="状态" v-model="searchParams.topicStatus" :options="statusOps"></v-select>
        <v-input label="发布人" v-model="searchParams.topicUser" placeholder="请输入完整姓名"></v-input>
        <v-input label="手机号码" v-model="searchParams.topicUserPhone" placeholder="请输入完整手机号"></v-input>
        <v-select2 label="所属组织" v-model="searchParams.orgId" v-bind="orgParams"></v-select2>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="regionParams"></v-select2>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
        <v-button text="查看" type="text" permission="update" @click="check(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>
<script>
import { getListURL, getTopicTypeURl } from './api'
import { setStatusOps } from './map'
import { communityParams, regionParams, orgParams } from '@/common/select2Params'
// import { createHTMLVNode } from 'common/vdom'
import moment from 'moment'

export default {
  name: 'InterActionList',
  created () {
    this.searchParams.startDate = moment().subtract(7, 'd').format('YYYY-MM-DD')
    this.searchParams.endDate = moment().format('YYYY-MM-DD')
  },
  data () {
    return {
      searchUrl: getListURL,
      statusOps: setStatusOps(1),
      orgParams,
      maxDate: new Date(),
      topicTypeParams: {
        searchUrl: getTopicTypeURl
      },
      communityParams,
      regionParams,
      taxNamesubjoin: {
        limitCnt: 20
      },
      searchParams: {
        startDate: '',
        endDate: '',
        topicTitle: '',
        topicType: '',
        topicStatus: undefined,
        topicUser: '',
        topicUserPhone: '',
        orgId: '',
        communityId: '',
        regionId: ''
      },
      headers: [
        {
          prop: 'topicContent',
          label: '内容'
        },
        {
          prop: 'topicTypeName',
          label: '所属应用'
        },
        {
          prop: 'relName',
          label: '发布人姓名',
          // formatter: (row) => {
          //   if (!row.admin) {
          //     let dom = `<div>
          //       ${row.relName}
          //       <span 
          //         id="admin-tag" 
          //         style="
          //           border: 1px solid #409EFF;
          //           border-radius: 3px;
          //           color: #409EFF;
          //           position: relative;
          //           top: -3px;
          //           font-size: 12px;
          //         ">管理员</span>
          //     </div>`
          //     return createHTMLVNode(this, dom)
          //   } else {
          //     return row.relName
          //   }
          // }
        },
        {
          prop: 'userName',
          label: '发布人昵称'
        },
        {
          prop: 'phone',
          label: '手机号'
        },
        {
          prop: 'topicPraise',
          label: '点赞数',
          align: 'right'
        },
        {
          prop: 'topicMsgcnt',
          label: '评论数',
          align: 'right'
        },
        {
          prop: 'orgName',
          label: '所属组织',
          formatter (row) {
            let orgText = row.orgName ? row.orgName : '/'
            return orgText
          }
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'statusText',
          label: '状态',
          formatter (row) {
            let statusText = ''
            if (row.topicStatus === 1 || row.topicStatus === 2) {
              statusText = '正常'
            } else {
              statusText = '关闭'
            }
            return statusText
          }
        },
        {
          prop: 'intime',
          label: '发布时间'
        }
      ]
    }
  },
  methods: {
    create () {
      this.$router.push({
        name: 'interActionForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'interActionForm',
        query: {
          id: row.id
        }
      })
    },
    check (row) {
      this.$router.push({
        name: 'interActionCheck',
        query: {
          id: row.id,
          neighborId: row.id,
          inuserId: row.insuer
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// .table-panel-wrapper {
//   .vnode-wrapper {
//     ::v-deep .el-table__row {
//       #admin-tag {

//       border: 1px solid #409EFF;
//       border-radius: 3px;
//       }
//     }
//   }
// }
</style>
