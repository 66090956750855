// 获取列表
const getListURL = `${API_CONFIG.baseURL}serverInteractionAction!list.action?neiStatus=0`
// 导出列表
const exportListURL = `${API_CONFIG.shopBaseURL}orderSettle/exportOrderSettle`
// 获取所属应用列表
const getTopicTypeURl = `${API_CONFIG.butlerBaseURL}communitySubject/getNeighborTopicType`
// 编辑
const editURL = `${API_CONFIG.baseURL}serverInteractionAction!update.action`
// 查询详情
const queryURL = `${API_CONFIG.baseURL}serverInteractionAction!queryOne.action`
// 上传
const uploadURL = `${API_CONFIG.uploadURL}?module=shopgoods`


// 删除
const deleteURL = `${API_CONFIG.baseURL}commentAction!delComment.action`
// 回复
const replyURL = `${API_CONFIG.baseURL}commentAction!reply.action`
// 查询详情
const detailURL = `${API_CONFIG.baseURL}commentAction!topicDetail.action`
// 评论置顶
const commentTop = `${API_CONFIG.butlerBaseURL}neighbor/isTopicComment`
// 帖子置顶(老)
const articleTop = `${API_CONFIG.baseURL}serverInteractionAction!updateTop.action`
// 评论（新）
const replyUrlNew = `${API_CONFIG.butlerBaseURL}neighbor/addComment`
// 评论列表（新）
const commentList = `${API_CONFIG.butlerBaseURL}neighbor/topic/commentList`
export {
  getListURL,
  exportListURL,
  getTopicTypeURl,
  editURL,
  queryURL,
  uploadURL,
  deleteURL,
  replyURL,
  detailURL,
  commentTop,
  articleTop,
  replyUrlNew,
  commentList
}
