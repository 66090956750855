import { mapHelper } from 'common/utils'

// 状态
const status = [
  {
    text: '正常',
    value: 1
  },
  {
    text: '关闭',
    value: 3
  }
]
// 帖子类型
const topicType = [
  {
    text: '邻里互助',
    value: 1
  },
  {
    text: '组局活动',
    value: 2
  },
  {
    text: '车位库房',
    value: 3
  },
  {
    text: '议事堂',
    value: 4
  },
  {
    text: '爱分享',
    value: 6
  },
  {
    text: '工作室',
    value: 7
  },
  {
    text: '社群',
    value: 8
  },
  {
    text: '红黑榜',
    value: 9
  },
  {
    text: '企业合作',
    value: 14
  },
  {
    text: '转租合租',
    value: 15
  }
]

const {
  map: statusMap,
  setOps: setStatusOps
} = mapHelper.setMap(status)
const {
  map: topicTypeMap,
  setOps: topicTypeOps
} = mapHelper.setMap(topicType)

export {
  statusMap,
  setStatusOps,
  topicTypeMap,
  topicTypeOps
}
